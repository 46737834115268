import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import SignUpBtn from "../SignUpBtn"

export default () => (
  <div className="section-4">
    <Container>
      <div id="signup-anchor" />
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div className="questions-signup-wrapper">
            <img
              className="mail-icon"
              alt=""
              src={require("../../assets/images/icon_mail.svg").default}
              width="130"
              height="130"
            />
            <div className="questions-signup" id="signup">
              <h1 className="text-blue">Questions or wish to sign up?</h1>
              <p>Learn more about adding your laboratory to CD30Labs.com</p>
              <SignUpBtn text="Contact Us Now" />
            </div>
          </div>
          <div className="questions-signup-wrapper-mobile" id="mobile-signup">
            <img
              className="mail-icon"
              alt=""
              src={require("../../assets/images/icon_mail.svg").default}
            />
            <h1 className="text-blue">Questions or wish to sign up?</h1>
            <p>Learn more about adding your laboratory to CD30Labs.com</p>
            <SignUpBtn text="Contact Us Now" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <p className="references">
            IHC = immunohistochemistry.
            <br />
            <br />
            <strong>References: 1.</strong> Federico M, Bellei M, Luminari S, et
            al. CD30+ expression in peripheral T-cell lymphomas (PTCLs): a
            subset analysis from the international, prospective T-Cell Project.{" "}
            <em>J Clin Oncol</em>. 2015;33(suppl 15):8552. <strong>2.</strong>{" "}
            Delsol G, Falini B, Müller-Hermelink HK, et al. Anaplastic large
            cell lymphoma (ALCL), ALK-positive. In: Swerdlow SH, Campo E, Harris
            NL, et al, eds.{" "}
            <em>
              WHO Classification of Tumours of Haematopoietic and Lymphoid
              Tissues
            </em>
            . 4th ed. Lyon, France: IARC; 2008:312-316. <strong>3.</strong>{" "}
            Mason DY, Harris NL, Delsol G, et al. Anaplastic large cell
            lymphoma, ALK-negative. In: Swerdlow SH, Campo E, Harris NL, et al,
            eds.{" "}
            <em>
              WHO Classification of Tumours of Haematopoietic and Lymphoid
              Tissues
            </em>
            . 4th ed. Lyon, France: IARC; 2008:317-319. <strong>4.</strong>{" "}
            Takeshita M, Akamatsu M, Ohshima K, et al. CD30 (Ki-1) expression in
            adult T-cell leukaemia/lymphoma is associated with distinctive
            immunohistological and clinical characteristics.{" "}
            <em>Histopathology</em>. 1995;26:539-546. <strong>5.</strong>{" "}
            Sabattini E, Pizzi M, Tabanelli V, et al. CD30 expression in
            peripheral T-cell lymphomas. <em>Haematologica</em>. 2013;98:e81-82.{" "}
            <strong>6.</strong> Pongpruttipan T, Kummalue T, Bedavanija A, et
            al. Aberrant antigenic expression in extranodal NK/T-cell lymphoma:
            a multiparameter study from Thailand. <em>Diagn Pathol</em>.
            2011;6:79. <strong>7.</strong> Stein H, Foss HD, Dürkop H, et al.
            CD30(+) anaplastic large cell lymphoma: a review of its
            histopathologic, genetic, and clinical features. <em>Blood</em>.
            2000;96:3681-3695. <strong>8.</strong> Edinger JT, Clark BZ,
            Pucevich BE, Geskin LJ, Swerdlow SH. CD30 expression and
            proliferative fraction in nontransformed mycosis fungoides.{" "}
            <em>Am J Surg Pathol</em>. 2009;33:1860-1868. <strong>9.</strong>{" "}
            Cerroni L, Rieger E, Hödl S, Kerl H. Clinicopathologic and
            immunologic features associated with transformation of mycosis
            fungoides to large-cell lymphoma. <em>Am J Surg Pathol</em>.
            1992;16:543-552. <strong>10.</strong> El Shabrawi-Caelen L, Kerl H,
            Cerroni L. Lymphomatoid papulosis: reappraisal of clinicopathologic
            presentation and classification into subtypes A, B, and C.{" "}
            <em>Arch Dermatol</em>. 2004;140:441-447.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
)
