import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

export default (props) => (
  <a href="mailto:CD30labs@seagen.com?subject=Lab%20list%20on%20CD30Labs.com&body=I%20am%20interested%20in%20learning%20more%20or%20want%20to%20add%20my%20lab%20to%20the%20list%20of%20those%20reporting%20CD30%20as%20a%20percentage.%20Please%20contact%20me%20to%20discuss%20at%20[insert%20contact%20details].">
    <button className="sign-up-btn">
      {props.text}{" "}
      <img alt="" src={require("../assets/images/arrow-right.svg").default} />
    </button>
  </a>
)
