import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import SectionOne from "../components/sections/SectionOne"
import SectionTwo from "../components/sections/SectionTwo"
import SectionThree from "../components/sections/SectionThree"
import SectionFour from "../components/sections/SectionFour"
import SectionDivider from "../components/sections/SectionDivider"
import PrintList from "../components/printlist/PrintList"

export default () => {
  function triggerPrintList() {
    if (
      document.querySelector(".print-list-wrapper").classList.contains("hidden")
    ) {
      document.querySelector(".print-list-wrapper").classList.remove("hidden")
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector(".print-list-wrapper").classList.add("hidden")
      document.querySelector("body").style.overflow = "auto"
    }
  }
  return (
    <Layout>
      <SectionOne triggerPrintList={triggerPrintList} />
      <SectionTwo triggerPrintList={triggerPrintList} />
      <div id="expression-anchor" />
      <SectionDivider
        text={
          "Screening for CD30 by IHC helps in the differential diagnosis of certain lymphomas "
        }
        id={"expression"}
      />
      <SectionThree />
      <SectionDivider />
      <SectionFour />
      <PrintList triggerPrintList={triggerPrintList} />
      <picture
        className="lab-list-trigger skew-text"
        onClick={() => triggerPrintList()}
      >
        <source
          media="(max-width: 768px)"
          srcSet={require("../assets/images/mobile_sticky.svg").default}
        />
        <img
          id="lab-list-trigger"
          alt=""
          src={require("../assets/images/desktop_sticky.svg").default}
        />
      </picture>
    </Layout>
  )
}
