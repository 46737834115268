import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

export default (props) => {
  return (
    <div className="section-1">
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h1 className="text-center skew-text text-white">
              CD30 expression is variable across lymphomas and should be
              reported as a percentage
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="skew-text text-center">
            <img
              className="microscope"
              alt=""
              src={require("../../assets/images/microscope.svg").default}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center skew-text">
            <div className="bitmap text-center">
              <h2 className="text-center text-white">
                See labs <br /> reporting CD30 <br /> down to 1%
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="lab-list text-center skew-text">
            <picture
              className="skew-text"
            >
              <source
                media="(max-width: 768px)"
                srcSet={require("../../assets/images/lab_list_mobile.svg").default}
              />
              <img
                alt=""
                src={require("../../assets/images/lab_list_desktop.svg").default}
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
