import React, { useRef } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ReactToPrint from "react-to-print"
import PrintSheet from "../printlist/PrintSheet"
import SignUpBtn from "../SignUpBtn"

export default props => {
  const componentRef = useRef()
  return (
    <div className="section-2" id="lab-list">
      <Container>
        <Row>
          <Col md={{ span: 9, offset: 1 }} className="section-2-header">
            <h1 className="text-blue">
              Innovative labs that report CD30 percentage expression
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 1 }}>
            <p>
              The following labs recognize the value of the CD30 biomarker and
              report CD30 expression quantitatively as a percentage (vs a
              binary result of positive/negative) for
              hematologists-oncologists.
            </p>
          </Col>
          <Col md={{ span: 5, offset: 1 }} className="d-flex icons-wrapper">
            <ReactToPrint
              trigger={() => (
                <div className="d-flex flex-column text-center align-items-center">
                  <img
                    alt=""
                    src={require("../../assets/images/icon_printer.svg").default}
                    className="print-icon"
                    width="90"
                    height="90"
                  />
                  <div className="text-blue text-uppercase fw-bold image-text">
                    Print List
                  </div>
                </div>
              )}
              content={() => componentRef.current}
            />
            <div className="hidden">
              <PrintSheet ref={componentRef} />
            </div>
            <div
              className="d-flex flex-column text-center see-list-wrapper align-items-center"
              onClick={() => props.triggerPrintList()}
            >
              <img
                alt=""
                src={require("../../assets/images/icon_microscope.svg").default}
                className="microscope-icon"
                width="90"
                height="90"
              />
              <div className="text-blue text-uppercase fw-bold image-text">
                See who's on the list
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="list-icon-bg">
        <Row>
          <Col md={{ span: 3, offset: 1 }} className="section-2-header">
            <h1 className="text-blue">Get on the list</h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 7, offset: 1 }}>
            <p>
              Are you a lab medical director wanting to learn more about
              quantitative reporting? Interested in joining the list of labs
              reporting CD30 as a percentage? Contact us today to be added to
              this online resource.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 7, offset: 1 }}>
            <SignUpBtn text="Sign Up Now" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
