import React, { useRef } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ReactToPrint from "react-to-print"
import PrintSheet from "./printlist/PrintSheet"

export default props => {
  const componentRef = useRef()
  return (
    <Row>
      <Col className="header">
        <ReactToPrint
          trigger={() => (
            <button className="sign-up-btn mt-10" id="print-list-btn">
              Print List{" "}
              <img
                alt=""
                src={require("../assets/images/icon_print_dark_blue.svg").default}
              />
            </button>
          )}
          content={() => componentRef.current}
        />
        <div className="hidden">
          <PrintSheet ref={componentRef} />
        </div>
      </Col>
    </Row>
  )
}
