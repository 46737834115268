import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PrintListChart from "./PrintListChart"
import SignUpBtn from "../SignUpBtn"
import ReactToPrintBtn from "../ReactToPrintBtn"

export default props => {
  return (
    <div className="print-list-wrapper hidden">
      <Container fluid>
        <Row>
          <Col md={{ span: 4 }} className="print-list-left">
            <Row>
              <Col>
                <img
                  alt=""
                  src={require("../../assets/images/cd30_logo.svg").default}
                  className="cd30-logo"
                />
              </Col>
              <Col
                className="d-flex flex-column align-items-end back-and-close-col"
                onClick={() => props.triggerPrintList()}
              >
                <img
                  alt=""
                  src={require("../../assets/images/icon_x.svg").default}
                  width="35"
                />
                <span className="text-white text-center back-text">BACK</span>
              </Col>
            </Row>
            <ReactToPrintBtn />
            <Row>
              <Col>
                <SignUpBtn text="Sign Up Now" />
              </Col>
            </Row>
          </Col>
          <Col className="print-list-right">
            <PrintListChart triggerPrintList={props.triggerPrintList} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
