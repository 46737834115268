import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

export default props => (
  <div className="section-divider" id={props.id}>
    <Container>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h1 className="text-white skew-text pt-30">{props.text}</h1>
        </Col>
      </Row>
    </Container>
  </div>
)
