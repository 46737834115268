import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Carousel } from "react-bootstrap"

import brochureIMG from '../../assets/images/brochure-img.png'
import PDF from "../../assets/Gen_Pro_CD30_Pathology_Brochure.pdf"

export default () => {
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)

  function handleSelect(selectedIndex, e) {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }

  return (
    <div className="section-3">
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div className="pathology-resources">
              <img
                className="brochure"
                alt=""
                src={brochureIMG}
                width='166'
                // height='194'
              />
              <div>
                <h2 className="text-blue">Pathology resources:</h2>
                <p>Best practices for CD30 reporting and quality control</p>
                <a className="download-button" href={PDF} target="_blank" rel='noopener noreferrer'>
                  Download <br /> Brochure{" "}
                  <img
                    alt=""
                    src={require("../../assets/images/icon_download.svg").default}
                  />
                </a>
              </div>
            </div>
            <div className="pathology-resources-mobile">
              <img
                className="brochure"
                alt=""
                src={brochureIMG}
              />
              <h2 className="text-blue">Pathology resources:</h2>
              <p>Best practices for CD30 reporting and quality control</p>
              <a className="download-button" href={PDF} target="_blank" rel='noopener noreferrer'>
                Download <br /> Brochure{" "}
                <img
                  alt=""
                  src={require("../../assets/images/icon_download.svg").default}
                />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="carousel-border" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="infographic-header text-center">Did you know?</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              interval={null}
            >
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-1.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-1.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-2.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-2.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-3.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-3.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-4.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-4.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-5.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-5.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-6.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-6.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-7.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-7.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-8.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-8.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
              <Carousel.Item>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={require("../../assets/images/infographics/Infographic_mobile-9.svg").default}
                  />
                  <img
                    alt=""
                    src={require("../../assets/images/infographics/Infographic-9.svg").default}
                    width="100%"
                  />
                </picture>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="carousel-border-bottom" />
          </Col>
        </Row>
        <Row>
          <div id="protocol-anchor" />
          <Col md={{ span: 6, offset: 1 }} id="protocol">
            <h1 className="text-blue">CD30 reporting protocols</h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 1 }}>
            <p>
              To aid in characterizing CD30 expression, Seagen
              recommends using the following descriptors in your pathology
              reports
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="chart-wrapper">
            <Row>
              <Col className="d-flex">
                <div className="chart-title text-center">
                  Report descriptors
                </div>
                <div className="chart-title text-center">Definition</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  CD30 detected (≥1% expression)
                </div>
                <div className="chart-right">
                  {" "}
                  Presence of ≥1% membranous and/or Golgi staining in tumor
                  (neoplastic) cells. Staining at any intensity should be
                  counted.
                  <br />
                  <br />
                  In cases where it is difficult to differentiate tumor cells
                  from normal lymphocytes, use total lymphocytes as the
                  denominator for determination of percentage
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  {"CD30 not detected (<1% expression)"}
                </div>
                <div className="chart-right">
                  {"Presence of <1% staining in tumor cells"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Staining intensity</div>
                <div className="chart-right">1+, 2+, 3+</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
