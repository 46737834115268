import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import SignUpBtn from "../SignUpBtn"
import ReactToPrintBtn from "../ReactToPrintBtn"

export default props => {
  return (
    <div className="print-list">
      <Container>
        <Row>
          <Col className="header">
            <img
              alt=""
              src={require('../../assets/images/cd30_logo.svg').default}
              className="cd30-logo"
            />
            <div
              className="d-flex flex-column align-items-end"
              onClick={() => props.triggerPrintList()}
            >
              <img
                alt=""
                src={require('../../assets/images/icon_x.svg').default}
                width="35"
              />
              <span className="text-white text-center back-text">BACK</span>
            </div>
          </Col>
        </Row>
        <ReactToPrintBtn />
        <Row>
          <Col className="header">
            <SignUpBtn text="Sign Up Now" />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} className="text-white">
            To help guide the diagnostic and treatment conversation, the
            following labs report CD30 expression quantitatively as a
            percentage. If you are a lab medical director,{' '}
            <a href="mailto:CD30labs@seagen.com?subject=Lab%20list%20on%20CD30Labs.com&body=I%20am%20interested%20in%20learning%20more%20or%20want%20to%20add%20my%20lab%20to%20the%20list%20of%20those%20reporting%20CD30%20as%20a%20percentage.%20Please%20contact%20me%20to%20discuss%20at%20[insert%20contact%20details].">
              sign up
            </a>{' '}
            to appear in this online resource today.
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} className="chart-wrapper">
            <Row>
              <Col className="d-flex">
                <h2 className="text-white">National</h2>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-title text-center ">Name</div>
                <div className="chart-title text-center">Website</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">ARUP Laboratories</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.aruplab.com" rel='noopener noreferrer'>
                    aruplab.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Genpath a Bioreference Company</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.bioreference.com" rel='noopener noreferrer'>
                    bioreference.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Genoptix</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.genoptix.com" rel='noopener noreferrer'>
                    genoptix.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Integrated Oncology, LabCorp Specialty Testing Group
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.integratedoncology.com" rel='noopener noreferrer'>
                    integratedoncology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Mayo Clinic Laboratories</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.mayocliniclabs.com" rel='noopener noreferrer'>
                    mayocliniclabs.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">NeoGenomics Laboratories</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.neogenomics.com" rel='noopener noreferrer'>
                    neogenomics.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Pathgroup</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathgroup.com" rel='noopener noreferrer'>
                    pathgroup.com
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} className="chart-wrapper-two">
            <Row>
              <Col className="d-flex">
                <h2 className="text-white">Northeast</h2>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-title text-center">Name</div>
                <div className="chart-title text-center">Website</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">ACCU Reference Medical Lab</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.accureference.com" rel='noopener noreferrer'>
                    accureference.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Cairo Diagnostics, LLC</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.cairodiagnostics.com" rel='noopener noreferrer'>
                    cairodiagnostics.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">CBLPath, Inc.</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.cblpath.com" rel='noopener noreferrer'>
                    cblpath.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">CPA Lab, Norton Healthcare</div>
                <div className="chart-right text-center">
                  <a
                    target="_blank"
                    href="http://nortonhealthcare.com/healthcare-professionals/cpa-lab"
                    rel='noopener noreferrer'
                  >
                    nortonhealthcare.com/healthcare-professionals/cpa-lab
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Dahl-Chase Diagnostic Services</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.DahlChase.com" rel='noopener noreferrer'>
                    DahlChase.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Diagnostic Hematology PC</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.diagnostichematology.com" rel='noopener noreferrer'>
                    diagnostichematology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Greensboro Pathology Associates - Aurora Diagnostics
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.greensboropathology.com" rel='noopener noreferrer'>
                    greensboropathology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Hallmark Pathology PC</div>
                <div className="chart-right text-center">
                  <a
                    target="_blank"
                    href="http://www.auroradx.com/hallmark-pathology.com"
                    rel='noopener noreferrer'
                  >
                    auroradx.com/hallmark-pathology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Michigan Medicine Laboratories (Mlabs)
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.mlabs.umich.edu" rel='noopener noreferrer'>
                    mlabs.umich.edu
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Morristown Pathology/AHS</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.MPAdx.com" rel='noopener noreferrer'>
                    MPAdx.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Pathline-Emerge</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathline-emerge.com" rel='noopener noreferrer'>
                    pathline-emerge.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Wilmington Pathology Associates, P.A.
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.wilmingtonpathology.com" rel='noopener noreferrer'>
                    wilmingtonpathology.com
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} className="chart-wrapper-two">
            <Row>
              <Col className="d-flex">
                <h2 className="text-white">Southeast</h2>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-title text-center">Name</div>
                <div className="chart-title text-center">Website</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Arkansas Pathology Associates</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathassociates.com" rel='noopener noreferrer'>
                    pathassociates.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Delta Pathology Group</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.deltapathology.com" rel='noopener noreferrer'>
                    deltapathology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Molecular Pathology Laboratory Network, Inc.
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.mplnet.com" rel='noopener noreferrer'>
                    mplnet.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Pathology Associates</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathologyassociates.net" rel='noopener noreferrer'>
                    pathologyassociates.net
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Pathology Laboratories of Arkansas
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathlabsofark.com/" rel='noopener noreferrer'>
                    pathlabsofark.com
                  </a>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex">
                <div className="chart-left">Protean Biodiagnostics Inc</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.proteanbiodx.com" rel='noopener noreferrer'>
                    proteanbiodx.com
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10 }} className="chart-wrapper-two">
            <Row>
              <Col className="d-flex">
                <h2 className="text-white">West</h2>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-title text-center">Name</div>
                <div className="chart-title text-center">Website</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Integrated Pathology</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.integrated-pathology.com" rel='noopener noreferrer'>
                    integrated-pathology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Pathologists Bio-Medical Laboratories, LLP
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pbmlabs.com" rel='noopener noreferrer'>
                    pbmlabs.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Pathology X Diagnostic Services
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.pathxdx.com" rel='noopener noreferrer'>
                    pathxdx.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  PhenoPath, a Quest Diagnostics Company
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.PhenoPath.com" rel='noopener noreferrer'>
                    PhenoPath.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Shasta Pathology Associates</div>
                <div className="chart-right text-center">
                  <a
                    target="_blank"
                    href="http://www.ShastaPathologyAssociates.com"
                    rel='noopener noreferrer'
                  >
                    ShastaPathologyAssociates.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Sterling Pathology National Laboratories
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.sterlingpath.com" rel='noopener noreferrer'>
                    sterlingpath.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">
                  Thousand Oaks Pathology Associates
                </div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://topathology.com/" rel='noopener noreferrer'>
                    topathology.com
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <div className="chart-left">Western Pathology Consultants</div>
                <div className="chart-right text-center">
                  <a target="_blank" href="http://www.westernpathology.com" rel='noopener noreferrer'>
                    westernpathology.com
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
